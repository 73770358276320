import {getWebsiteIntent, getCompanyList, getCompanySession, getVerticals} from '@/api/website-intent';
import { getCompanyGroupList,getPeopleGroupList } from '@/api/contact';

const state = {
  intentSelectedModule: '',


  domainId: null,
  domainSearchId: null,
  trackingDomainId: null,
  leadId: null,
  filter: {
    start_date: null,
    end_date: null,
    company_name: null,
    locations: [],
    verticals: [],
    technologies: [],
    advertisements: [],
    pageview_greater_than: null,
    session_greater_than: null,
    company_groups: [],
    flag: false,
  },
  websiteList: [],
  companyList: [],
  filterCompanyList: [],
  companyInfo: {},
  passInfo: {},
  pageInfo: {
    page: 1,
    pageSize: 100,
  },
  verticalList: [],
  companyGroupList: [],
  peopleGroupList: [],
  filterShow: false,
  locationList: [],
  industryList: [],
  adList: [],
  techList: [],
  jobFunctionList: [],
}

const mutations = {
  setIntentSelectedModule(state, payload) {
    state.intentSelectedModule = payload
  },


  
  setDomainId(state, domainId) {
    state.domainId = domainId
  },
  setDomainSearchId(state, domainSearchId) {
    state.domainSearchId = domainSearchId;
  },
  setTrackingDomainId(state, trackingDomainId) {
    state.trackingDomainId = trackingDomainId
  },
  setLeadId(state, leadId) {
    state.leadId = leadId
  },
  setWebsiteList(state, payload) {
    state.websiteList = payload;
  },
  setCompanyList(state, payload) {
      state.companyList = payload;
  },
  setSearchParams(state, payload) {
    for(let key in payload) {
      state.filter[key] = payload[key];
    } 
  },
  resetSearchParams(state) {
    state.filter = {
      start_date: '',
      end_date: '',
      company_name: '',
      locations: [],
      verticals: [],
      technologies: [],
      advertisements: [],
      pageview_greater_than: null,
      session_greater_than: null,
      company_groups: [],
      flag: false,
    }
  },
  setCompanyInfo(state, payload) {
    state.companyInfo = payload;
  },
  setPassInfo(state, payload) {
    state.passInfo = payload;
  },
  setPageInfo(state, payload) {
    state.pageInfo = payload
  },
  setVerticals(state, payload) {
    state.verticals = payload;
  },
  setCompanyGroupList(state, payload) {
    state.companyGroupList = payload;
  },
  setPeopleGroupList(state, payload) {
    state.peopleGroupList = payload;
  },
  setFilterCompanyList(state, payload) {
    state.filterCompanyList = payload;
  } ,
  // intent filter 的展示/隐藏
  setFilterShow(state, payload) {
    state.filterShow = payload;
  },
  setLocationList(state, payload) {
    state.locationList = payload;
  },
  setIndustryList(state, payload) {
    state.industryList = payload;
  },
  setAdList(state, payload) {
    state.adList = payload;
  },
  setJobFunctionList(state, payload) {
    state.jobFunctionList = payload; 
  },
  setTechList(state, payload) {
    state.techList = payload;
  },
}

const actions = {
  async changeWebsiteList({ commit }, params) {
    const { data } = await getWebsiteIntent(params);
    const nowTime = new Date().getTime()
    commit('setWebsiteList', data.map((item) => ({
      ...item,
      leftDays: (new Date(item.expired_at).getTime() - nowTime) / 1000 / 60 / 60 / 24
    })));
    return data;
  },
  async changeCompanyList({ commit }, params) {
    const { data } = await getCompanyList(params.params);
    if (params.reset) {
      commit('setCompanyList', data.leads ? data.leads: []);
    } else {
      commit('setCompanyList', [...state.companyList, ...data.leads]);
    }
    return {
      list: params.reset ? (data.leads ? data.leads: []): [...state.companyList, ...data.leads],
      pagination: data.pagination
    };
  },
  async getCompanySession(context,data) {
    const res = await getCompanySession(data);
    context.commit('setCompanyInfo', res.data)
    return res.data;
  },
  async getIndustryList(context,data) {
    const res = await getVerticals(data);
    context.commit('setVerticals', res.data)
    return res.data;
  },
  async getFilterCompanyList(context, data) {
    const res = await getVerticals(data);
    context.commit('setFilterCompanyList', res.data)
    return res.data;
  },
  async getList({ commit,state, rootState }, data) {
		const profileId = rootState.user.currentId.profile_id;
		const { isPersonal,type } = data; let result = null;
		// commit('setType', type);
		// commit('setPersonal', isPersonal);
		const params = {
			profile_id: profileId,
			is_personal: isPersonal,
		};
    if(type === "PEOPLE") {
      result = await getPeopleGroupList(params);
      commit('setPeopleGroupList',result.data);
    } else if (type === 'COMPANY'){
      result = await getCompanyGroupList(params);
      commit('setCompanyGroupList',result.data);
    };
	},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}