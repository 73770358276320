import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}; //解决路由重复点击报错问题

Vue.use(VueRouter);

import dashboard from "@/views/dashboard";

export const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/signup"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("@/views/forgot"),
  },

  {
    path: "/",
    component: dashboard,
    redirect: { name: "account" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home"),
        meta: { title: "Home" },
      },
      {
        path: "search",
        name: "Search",
        component: () => import("@/views/search"),
        redirect: { name: "people" },
        // redirect:{name:'company'},
        meta: { title: "Prospect" },
        children: [
          {
            path: "people",
            name: "people",
            component: () => import("@/views/search/people"),
            meta: { title: "Prospect", keepAlive: true },
          },
          {
            path: "company",
            name: "company",
            component: () => import("@/views/search/companies/index"),
            meta: { title: "Companies", keepAlive: true },
          },
        ],
      },
      {
        path: "intent-signals",
        name: "intent-signals",
        component: () => import("@/views/intent-signals"),
        redirect: { name: "intent" },
        meta: { title: "Intent_Signals" },
        children: [
          {
            path: "intent",
            name: "intent",
            component: () => import("@/views/intent-signals/website-intent"),
            meta: { title: "Intent_Signals" },
          },
          {
            path: "market",
            name: "market",
            component: () => import("@/views/intent-signals/market-intent"),
            meta: { title: "Market Intent" },
          },
        ],
      },
      {
        path: "sequence",
        name: "sequence",
        component: () => import("@/views/sequence/index"),
        meta: { title: "Sequences" },
        redirect: { name: "sequence-list" },
        children: [
          {
            path: "sequence-list",
            name: "sequence-list",
            component: () => import("@/views/sequence/sequence-list"),
            meta: { title: "Sequences" },
          },
          {
            path: "sequence-templates",
            name: "sequence-templates",
            component: () =>
              import("@/views/sequence/templates/sequence-templates.vue"),
            meta: { title: "Templates" },
          },
          // {
          //     path: 'sequence-analytics',
          //     name: 'sequence-analytics',
          //     component: () => import('@/views/sequence/sequence-analytics'),
          //     meta: {title: 'Analytics' },
          // },
          // {
          //   path: 'sequence-meetings',
          //   name: 'sequence-meetings',
          //   component: () => import('@/views/sequence/sequence-meetings'),
          //   meta: {title: 'Meetings' },
          // },
          {
            path: "sequence-settings",
            name: "sequence-settings",
            component: () => import("@/views/sequence/sequence-settings"),
            meta: { title: "Settings" },
          },
        ],
      },
      {
        path: "tools",
        name: "tools",
        component: () => import("@/views/tools"),
        meta: { title: "Tools" },
      },
      {
        path: "services",
        name: "services",
        redirect: { name: "overview" },
        component: () => import("@/views/services"),
        meta: { title: "Services" },
        children: [
          {
            path: "overview",
            name: "overview",
            component: () => import("@/views/services/overview"),
            meta: { title: "Service Overview" },
          },
          {
            path: "teams",
            name: "marteamsket",
            component: () => import("@/views/services/overview"),
            meta: { title: "serviceMenuTeams" },
          },
        ],
      },
      /* {
        path: 'users',/!*需要传递参数*!/
        name: 'users',
        component: () => import('@/views/users'),
        meta:{title:'Users'},
      },*/
      /*------setting相关路由-------*/
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/setting/index"),
        redirect: { name: "account" },
        meta: { title: "Setting" },
        children: [
          {
            path: "profile",
            name: "account",
            component: () => import("@/views/setting/profile"),
            meta: { index: "1" },
          },
          {
            path: "current-usage",
            name: "currentUsage",
            component: () => import("@/views/setting/current-usage"),
            meta: { index: "1" },
          },
          {
            path: "upgrade-plan",
            name: "upgrade",
            component: () => import("@/views/setting/upgrade"),
            meta: { index: "1" },
          },

          {
            path: "billing",
            name: "billing",
            component: () => import("@/views/setting/billing/index"),
            meta: { index: "1" },
          },
          {
            path: "integrations",
            name: "integrations",
            component: () => import("@/views/setting/integrations"),
            meta: { index: "2" },
          },
          {
            path: "customFields",
            name: "customField",
            component: () => import("@/views/setting/customField.vue"),
            meta: { index: "3" },
          },
          {
            path: "roles",
            name: "permission",
            component: () => import("@/views/setting/role/index.vue"),
            children: [
              {
                path: "role-detail/:id",
                name: "role-detail",
                component: () => import("@/views/setting/role/permission.vue"),
              },
            ],
          },
          {
            path: "teams",
            name: "team",
            component: () => import("@/views/setting/team/index.vue"),
            children: [
              {
                path: "team-detail/:id",
                name: "team-detail",
                component: () => import("@/views/setting/team/permission.vue"),
              },
            ],
          },
          {
            path: "extensions",
            name: "extension",
            component: () => import("@/views/setting/extension.vue"),
          },
          {
            path: "notifications",
            name: "notification",
            component: () => import("@/views/setting/notification/index.vue"),
          },
          {
            path: "apiKeys",
            name: "apiKeys",
            component: () => import("@/views/setting/ApiKeys/index.vue"),
          },
          {
            path: "users",
            name: "users",
            component: () => import("@/views/setting/users/index.vue"),
          },
        ],
      },
      {
        path: "/company-detail/:domain_search_id",
        name: "company-detail",
        meta: { title: "companyDetail" },
        component: () => import("@/views/search/companies/company-detail"),
      },
      {
        path: "sequence-creat/:id/:name",
        name: "sequence-creat",
        meta: { title: "companyDetail" },
        component: () => import("@/views/sequence/sequence-list/new-sequence"),
      },
      {
        path: "sequence-edit",
        name: "sequence-edit",
        meta: { title: "companyDetail" },
        component: () =>
          import("@/views/sequence/sequence-list/sequence-detail/createSteps"),
      },
      {
        path: "sequence-detail/:id",
        name: "sequence-detail",
        meta: { title: "companyDetail" },
        component: () =>
          import("@/views/sequence/sequence-list/sequence-detail"),
      },
    ],
  },

  {
    path: "/reset",
    name: "Reset",
    component: () => import("@/views/reset/reset"),
  },

  /*----------404--------------*/
  {
    path: "/:catchAll(.*)",
    name: 404,
    component: () => import("@/views/404"),
    // hidden: true
  },
];

const router = new VueRouter({
  routes,
});

export default router;
