import { getEmployeeList } from "@/api/website-intent";

const state = {
  employeeFilterParams: {
    verticals: [],
    technologies: [],
    people_titles: [],
    // companies: [],
    people_groups: [],
    people_name: null,
    people_locations: [],
    // employees: [],
    department_functions: [],
    departments: [],
  },
  employeeTempParams: {
    verticals: [],
    technologies: [],
    people_titles: [],
    people_groups: [],
    people_name: null,
    people_locations: [],
    department_functions: [],
    departments: [],
  },
  tableLoading: false,
  list: [],
  checkedList: [],
  paginationInfo: {
    page: 1,
    per_page: 25,
    total_entries: 0,
  },
}

const mutations = {
  setTableLoading(state, payload) {
    state.tableLoading = payload;
  },
  setList(state, payload) {
    state.list = payload;
  },
  setCheckedList(state, payload) {
    state.checkedList = payload;
  },
  setPaginationInfo(state, payload) {
    state.paginationInfo = payload;
  },
  setEmployeeFilterParams(state, payload) {
    for(let key in payload) {
      state.employeeFilterParams[key] = payload[key];
    } 
  },
  setEmployeeTempParams(state, payload) {
    for(let key in payload) {
      state.employeeTempParams[key] = payload[key];
    } 
  },
  resetEmployeeFilterParams(state) {
    state.employeeFilterParams = {
      verticals: [],
      technologies: [],
      people_titles: [],
      companies: [],
      people_groups: [],
      people_name: null,
      people_locations: [],
      employees: [],
      department_functions: [],
      departments: [],
    }
  },
  setIntentRowAddSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.list = state.list.map((item) => {
      if (item.people_search_id === people_search_id) {
        if (item.saved_lists) {
          const groupIds = item.saved_lists.map((groupItem) => groupItem.group_id);
          return groupIds.includes(group.group_id) ? item : { 
            ...item,
            saved_lists: [...item.saved_lists, group],
          }
        } else {
          return {
            ...item,
            saved_lists: [group],
          }
        }
      } else {
        return item;
      };
    })
  },
  setIntentRowDeleteSavedList(state, payload) {
    const { people_search_id, group } = payload;
    state.list = state.list.map((item) => {
      if (item.people_search_id === people_search_id) {
        return {
          ...item,
          saved_lists: item.saved_lists.filter((groupItem) => groupItem.group_id !== group.group_id),
        }
      } else {
        return item;
      }
    })
  },
  updateTableContact(state, payload) {
    const { row, people_search_id } = payload
    state.list.map((item) => {
      if (item.people_search_id === people_search_id) {
        item.contacts = [
          ...(row.emails
            ? row.emails.map((item) => ({
                ...item,
                is_redeemed: true,
              }))
            : []),
          ...(row.phones
            ? row.phones.map((item) => ({
                ...item,
                is_redeemed: true,
              }))
            : []),
        ];
      }
    });
  }
}

const actions = { 
  async getEmployees({ commit }, params) {
    try {
      commit('setTableLoading', true);
      const { data } = await getEmployeeList(params);
      if (data && data.pagination) {
        commit('setList', data.peoples ? data.peoples.map((item) => ({
          ...item,
          isContactOpen: false,
          isAddOpen: false,
          isPhoneOpen: false,
          isSaveOpen: false
        })): [])
        commit('setPaginationInfo', data.pagination);
        commit('setTableLoading', false);
        return data?.pagination.total_entries
        // data.peoples.map((item) => {
        //   if (this.allID.includes(item.people_search_id.toString())) {
        //     this.$refs.recordTable.toggleRowSelection(item, true);
        //   }
        // });
      }
      commit('setTableLoading', false);
      return 0;
    } catch (e) {
      commit('setTableLoading', false);
    }
  },
}
 
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
