export const ERR_BAD_REQUEST = 400 //
export const ERR_UNAUTHORIZED = 401 //未登录/token过期/同一账号在异地登陆
export const ERR_NOT_FOUND = 404
export const ERR_ALREADY_EXISTS = 422;
export const ERR_TOO_MANY_REQUEST = 429 
export const ERR_MAX_QUERY = 416  // 查询量达到最大

// code: 40201
// status: 402
// desc: No active payment option


//  code: 40208,
//  status: 402,
//  desc: payment method requires 3D Security